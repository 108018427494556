import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import withStepper from 'common/components/hocs/withStepper';
import Button from 'common/components/Button';

interface WithStepperDemoState {
  open: {
    [componentName: string]: boolean;
  };
}

class WithStepperDemo extends Component<any, WithStepperDemoState> {
  state = {
    open: {
      withStepCircles: false,
      withoutStepCircles: false
    }
  };

  render() {
    const steps = [
      {
        title: 'Step 1',
        component: () => <div>Here is the first step!</div>
      },
      {
        title: 'Step 2',
        component: () => <div>Here is the second step!</div>
      },
      {
        title: 'Step 3',
        component: () => <div>Here is the third and final step!</div>
      }
    ];

    const showComponent = (name: string) => () => {
      this.setState({ open: { [name]: true } });
    };
    const hideComponent = (name: string) => () => {
      this.setState({ open: { [name]: false } });
    };

    const options = (name: string, overrides: any = {}) =>
      _.merge(
        {
          steps,
          onDismiss: hideComponent(name),
          modalTitle: `Test Modal: ${name}`,
          selectedFinish: {
            action: hideComponent(name),
            actionLabel: 'Done'
          }
        },
        overrides
      );

    const ComponentWithSteps = withStepper(options('withStepCircles'));
    const ComponentWithoutSteps = withStepper(options('withoutStepCircles', { showSteps: false }));

    return (
      <div>
        <p>
          The withStepper is a higher order component which creates a stepped modal. It will manage state
          (like which step you're on), and you can pass in state and callbacks.
        </p>
        <h3>Managing state</h3>
        <p>
          withStepper provides two ways to manage state:
          <ul>
            <li>
              <h6>handleStepStateChange and stepState</h6>
              <p>
                This is state that needs to be namespaced by step. It should be used primarily for things like
                `disableNext`, which only matter for the current step.
              </p>
            </li>
            <li>
              <h6>handleWorkflowStateChange and workflowState</h6>
              <p>
                This is state that needs to be shared by all steps. It is not namespaced, so if multiple steps
                set the same key, they will override each other.
              </p>
            </li>
          </ul>
        </p>
        <p>
          <Button onClick={showComponent('withStepCircles')}>Show stepped modal with step circles</Button>
          {this.state.open.withStepCircles && <ComponentWithSteps />}
        </p>
        <p>
          <Button onClick={showComponent('withoutStepCircles')}>
            Show stepped modal without step circles
          </Button>
          {this.state.open.withoutStepCircles && <ComponentWithoutSteps />}
        </p>
      </div>
    );
  }
}

window.onload = () => {
  ReactDOM.render(React.createElement(WithStepperDemo), document.getElementById('component-demo'));
};
